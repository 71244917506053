
import { Goals, Roles, Users } from "@/api";
import { Role, User } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: { ValidationProvider, ValidationObserver, PageTitle }
})
export default class EditUserPage extends Vue {
  headers = [{ icon: "mdi-account-lock", header: "Edit User" }];
  buttonsArray = [
    {
      text: "Back to list",
      icon: "",
      color: "gray",
      action: "back"
    }
  ];
  user: User = {
    name: "",
    email: "",
    roles: [],
    active: true
  };
  isLoading = false;
  goalsExist = false;
  userGoals: any = [];
  goalsList: any = [
    {
      text: "Existing Clients",
      value: "Existing Customers Target"
    },
    {
      text: "New Clients",
      value: "New Customers Target"
    }
  ];
  goal = {
    userId: Number(this.userId),
    goals: [
      {
        amount: undefined,
        goalName: undefined
      }
    ]
  };
  isGoalsFormShown = false;
  roles: Array<Role> = [];
  selectedRoles: Array<number> = [];
  get userId() {
    return this.$route.params.id;
  }
  async created() {
    try {
      [this.roles, this.user, this.userGoals] = await Promise.all([
        Roles.list(),
        Users.info(this.userId),
        Goals.getUserGoalsData(this.userId)
      ]);
      if (this.userGoals.data.length > 0) {
        this.goal.goals = [
          ...this.userGoals.data.map((goal: any) => {
            this.goalsList.forEach((goalOption: any) => {
              if (goal.goalName === goalOption.value) {
                goalOption.disabled = true;
              }
            });
            return {
              id: goal.goalId,
              amount: goal.amount,
              goalName: goal.goalName
            };
          })
        ];
        this.goalsExist = true;
        this.isGoalsFormShown = true;
      }
      this.handleAvailableGoals();
      this.selectedRoles = this.user.roles?.map((item: Role) => item.id);
    } catch (e) {
      await this.$error(e.errors);
    }
  }
  addGoal() {
    this.goal.goals.push({
      amount: undefined,
      goalName: undefined
    });
  }
  handleAvailableGoals() {
    this.goalsList.forEach((goal: any, index: number) => {
      if (this.goal.goals.some(g => g.goalName === goal.value)) {
        this.goalsList[index].disabled = true;
      } else {
        this.goalsList[index].disabled = false;
      }
    });
  }

  async removeGoal(goalIndex: number, goalId: number) {
    this.isLoading = true;
    try {
      if (goalId) {
        await Goals.deleteSingleGoal(goalId);
        await this.$success("<strong>Success!</strong> Goal has been deleted!");
      }
      this.isLoading = false;
    } catch (e) {
      await this.$error(e.errors);
      this.isLoading = false;
    }
    // this.handleAvailableGoals();
    this.goal.goals.splice(goalIndex, 1);

    if (this.goal.goals.length === 0) {
      this.isGoalsFormShown = false;
    }
  }
  async handleGoalsForm(formState: boolean) {
    if (!formState) {
      try {
        await Goals.deleteAllGoals(this.goal);
        this.goal.goals = [
          {
            amount: undefined,
            goalName: undefined
          }
        ];
        this.isGoalsFormShown = formState;
      } catch (e) {
        await this.$error(e.errors);
      }
    } else {
      this.isGoalsFormShown = formState;
    }
  }
  async save() {
    try {
      const { name, email, active = true } = this.user;
      await Users.update(this.userId, {
        name,
        email,
        active,
        roles: this.selectedRoles
      });
      await this.$success("<strong>Success!</strong> User has been updated!");
    } catch (e) {
      (this.$refs.userForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(e.errors);
      await this.$error(e.errors);
    }
  }

  async saveGoal() {
    try {
      // const goalsList = [];
      // goalsList.push(this.goal)
      if (this.goalsExist) {
        await Goals.updateGoal(this.goal);
        await this.$success(
          "<strong>Success!</strong> User Goal has been updated!"
        );
      } else {
        await Goals.createGoal(this.goal);
        await this.$success(
          "<strong>Success!</strong> User Goal has been created!"
        );
      }
    } catch (e) {
      (this.$refs.userGoalsForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(e.errors);
      await this.$error(e.errors);
    }
  }
  @Watch("goal.goals")
  updGoals() {
    this.handleAvailableGoals();
  }
}
